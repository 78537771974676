'use strict';

var cov_tkuk1layk = function () {
  var path = '/Users/ryanluton/Developer/lutonmedia/yourdomain.cheap/src/app/app.js',
      hash = 'f0ea44d3ea8b5dfc249431cb780cb5c157be8a41',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/ryanluton/Developer/lutonmedia/yourdomain.cheap/src/app/app.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 28,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 14
        },
        end: {
          line: 7,
          column: 3
        }
      },
      '2': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 40
        }
      },
      '3': {
        start: {
          line: 12,
          column: 17
        },
        end: {
          line: 12,
          column: 21
        }
      },
      '4': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 20,
          column: 6
        }
      },
      '5': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 17,
          column: 7
        }
      },
      '6': {
        start: {
          line: 15,
          column: 8
        },
        end: {
          line: 15,
          column: 41
        }
      },
      '7': {
        start: {
          line: 16,
          column: 8
        },
        end: {
          line: 16,
          column: 15
        }
      },
      '8': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 18,
          column: 60
        }
      },
      '9': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 49
        }
      },
      '10': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 21,
          column: 31
        }
      },
      '11': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 22,
          column: 31
        }
      },
      '12': {
        start: {
          line: 25,
          column: 2
        },
        end: {
          line: 27,
          column: 27
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 28,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 11,
            column: 11
          },
          end: {
            line: 11,
            column: 21
          }
        },
        loc: {
          start: {
            line: 11,
            column: 36
          },
          end: {
            line: 23,
            column: 3
          }
        },
        line: 11
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 13,
            column: 19
          },
          end: {
            line: 13,
            column: 20
          }
        },
        loc: {
          start: {
            line: 13,
            column: 25
          },
          end: {
            line: 20,
            column: 5
          }
        },
        line: 13
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 21,
            column: 22
          },
          end: {
            line: 21,
            column: 23
          }
        },
        loc: {
          start: {
            line: 21,
            column: 28
          },
          end: {
            line: 21,
            column: 30
          }
        },
        line: 21
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 22,
            column: 22
          },
          end: {
            line: 22,
            column: 23
          }
        },
        loc: {
          start: {
            line: 22,
            column: 28
          },
          end: {
            line: 22,
            column: 30
          }
        },
        line: 22
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 14,
            column: 6
          },
          end: {
            line: 17,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 14,
            column: 6
          },
          end: {
            line: 17,
            column: 7
          }
        }, {
          start: {
            line: 14,
            column: 6
          },
          end: {
            line: 17,
            column: 7
          }
        }],
        line: 14
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_tkuk1layk.s[0];
(function () {
  ++cov_tkuk1layk.f[0];

  var app = (++cov_tkuk1layk.s[1], {
    bindings: {},
    templateUrl: 'app/app.html',
    controller: controller
  });

  ++cov_tkuk1layk.s[2];
  controller.$inject = ['$stateParams'];

  function controller($stateParams) {
    ++cov_tkuk1layk.f[1];

    var self = (++cov_tkuk1layk.s[3], this);
    ++cov_tkuk1layk.s[4];
    self.$onInit = function () {
      ++cov_tkuk1layk.f[2];
      ++cov_tkuk1layk.s[5];

      if (angular.isUndefined($stateParams.domain)) {
        ++cov_tkuk1layk.b[0][0];
        ++cov_tkuk1layk.s[6];

        self.domain = 'yourdomain.cheap';
        ++cov_tkuk1layk.s[7];
        return;
      } else {
        ++cov_tkuk1layk.b[0][1];
      }
      ++cov_tkuk1layk.s[8];
      self.domain = decodeURIComponent($stateParams.domain);
      ++cov_tkuk1layk.s[9];
      self.domain = self.domain.replace('/', '');
    };
    ++cov_tkuk1layk.s[10];
    self.$onUpdates = function () {
      ++cov_tkuk1layk.f[3];
    };
    ++cov_tkuk1layk.s[11];
    self.$onDestroy = function () {
      ++cov_tkuk1layk.f[4];
    };
  }

  ++cov_tkuk1layk.s[12];
  angular.module('app').component('app', app);
})();